import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser";
import { Observable, of } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { PATH_REDIRECT_TO, PATH_ROUTE_LOGIN } from "../../routes";
import { UserPermitsService } from "../services";

@Injectable({ providedIn: "root" })
export class MustBeAzureAuthenticate implements CanActivate {
	constructor(
		private msalService: MsalService,
		private router: Router,
		private msalBroadcastService: MsalBroadcastService,
		private userPermitsService: UserPermitsService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		const url = state.url;

		return this.msalBroadcastService.inProgress$.pipe(
			filter(
				(status: InteractionStatus) => status === InteractionStatus.None
			),
			switchMap(() => {
				const activeAccount =
					this.msalService.instance.getActiveAccount();

				// si tiene cuenta activada
				if (activeAccount) {

					if (url !== PATH_ROUTE_LOGIN) return of(true);

					this.router.navigate([PATH_REDIRECT_TO]);

					return of(false);
				} else {
					// si no tiene cuenta activada, pero tiene cuentas por activar

					// obtienen las cuentas
					const accounts = this.msalService.instance.getAllAccounts();

					// si tiene cuentas
					if (accounts.length > 0) {
						// establece la 1era cuenta de la lista
						this.msalService.instance.setActiveAccount(accounts[0]);

						// cuando se activa la cuenta llamar a API para registrar login (Bitacora)
						// todo: aplicar unsubscribe, o intentar con metodo wrapper al servicio
						this.userPermitsService
							.recordLogUserToSession()
							.subscribe();

						// para todas la rutas /* accede
						if (url !== PATH_ROUTE_LOGIN) return of(true);

						// si la url es login
						this.router.navigate([PATH_REDIRECT_TO]);
						return of(false);
					} else {
						// si usuario no tiene session activa, redirigir a login

						// si la rutas son publicas, simplemente dejar pasar a la ruta
						if ([PATH_ROUTE_LOGIN].includes(url)) {
							return of(true);
						}
						// para cualquier ruta '/*'
						else {
							this.router.navigate([PATH_ROUTE_LOGIN]);
							return of(false);
						}
					}
				}
			})
		);
	}
}
