import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-bot-telegram',
  templateUrl: './home-bot-telegram.component.html',
  styleUrls: ['./home-bot-telegram.component.scss']
})
export class HomeBotTelegramComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
