<mat-toolbar class="justify-between bot-telegram-toolbar">

    <div>
      <div class="row-centered">
        <img src="../../../../assets/logo-ipt.png" alt="portal" [style.width]="'48px'">
        <span class="ml-4">Lean Noc BOT</span>
      </div>   
    </div>
  
    <nav mat-tab-nav-bar>
      <a mat-tab-link routerLinkActive #rla="routerLinkActive"
        [active]="rla.isActive" [routerLink]="route.to" ariaCurrentWhenActive="page">
        <mat-icon aria-hidden="false" [attr.aria-label]="route.key">{{ route.icon }}</mat-icon>
        <span>{{ route.text }}</span>
      </a>
    </nav>

    <div class="extra-menu">
      <button mat-button matTooltip="Ver Perfil" [matMenuTriggerFor]="menu" aria-label="menu">
        <mat-icon>person</mat-icon>
      </button>
      <mat-menu #menu="matMenu" ariaLabelledby="Ver Perfil">
        <mat-card class="profile-card">
          <mat-card-header>
            <mat-icon mat-card-avatar>account_circle</mat-icon>
            <mat-card-title>{{ fullname }}</mat-card-title>
            <mat-card-subtitle>{{ useremail }}</mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </mat-menu>
      <button mat-button routerLink="../../" routerLinkActive="active" matTooltip="Ir al Portal">
        <mat-icon>home</mat-icon>
      </button>
      <button mat-button (click)="onLogout()" matTooltip="Cerrar Sesión">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
    
  </mat-toolbar>
