import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { NotificationService } from "../notification/notification.service";
import { OptionsAlert } from "./dialog-alert.service";

@Component({
	selector: "app-dialog-alert",
	templateUrl: "./dialog-alert.component.html",
	styleUrls: ["./dialog-alert.component.scss"],
})
export class DialogAlertComponent implements OnInit, OnDestroy {
	loading: boolean = false;

	title: string;
	message: string;
	typeAlert: string;
	icon: string;
	subscription: Subscription;
	durationResponse: number;
	errorIntoAlert: boolean;
	errors: string[];

	constructor(
		public dialogRef: MatDialogRef<DialogAlertComponent>,
		private notificationService: NotificationService,
		@Inject(MAT_DIALOG_DATA) public data: OptionsAlert
	) {}

	ngOnInit(): void {
		this.title = this.data.title;
		this.message = this.data.message;
		this.typeAlert = this.data.typeAlert;
		this.icon = this.data.icon;
		this.durationResponse = this.data.durationResponse;
		this.errorIntoAlert = this.data.errorIntoAlert;
	}
	ngOnDestroy(): void {
		if (this.subscription) this.subscription.unsubscribe();
	}

	// refactor: reduce cognitive complexity
	onOk() {
		if (this.data.callback) {
			this.loading = true;
			this.subscription = this.data
				.callback()
				.pipe(finalize(() => (this.loading = false)))
				.subscribe({
					next: (response) => {
						if (this.data.withAlertSucess) {
							this.notificationService.success({
								message: this.data.messageSuccess,
								duration: this.durationResponse,
							});
						}
						const result = this.data.returnSuccessResponse
							? response
							: true;

						this.dialogRef.close(result);
					},
					error: ({ error }) => {
						if (this.data.withAlertError) {
							if (this.errorIntoAlert) {
								const { errors } = error;
								if (Array.isArray(errors)) this.errors = errors;
								else if (typeof errors === "string")
									this.errors = [errors];
								else
									this.errors = [
										"Oops, ha ocurrido un Error!",
									];
							} else {
								if (
									error?.errors &&
									typeof error.errors === "string"
								) {
									this.notificationService.error({
										message: error.errors,
									});
								} else if (
									error.detail &&
									typeof error.detail === "string"
								) {
									this.notificationService.error({
										message: error.detail,
									});
								} else this.notificationService.error();
							}
						}
					},
					complete: () => {
						if (this.data.closeDialogAfterCompleteCallback) {
							this.dialogRef.close(true);
						}
					},
				});
		} else {
			this.dialogRef.close(true);
		}
		// service delete
	}
}
