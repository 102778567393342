import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from "@angular/router";

import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { PATH_REDIRECT_TO } from "src/app/routes";
import { NotificationService } from "src/app/shared/services";
import { AuthService } from "../services/auth.service";
import { HotToastService } from "@ngneat/hot-toast";
import { MESSAGE_HTML_ERROR_ACCESS } from "../data/constants";

@Injectable({ providedIn: "root" })
export class MustHavePermissions implements CanActivate {
	constructor(
		private router: Router,
		private authService: AuthService,
		private notificationService: NotificationService,
		private hotToastService: HotToastService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return this.authService.permissions.pipe(
			switchMap(() => {
				const hasPermission = this.authService.checkUserPermissions([]);

				if (hasPermission) return of(true);

				this.router.navigate([PATH_REDIRECT_TO]).then(() => {
					this.hotToastService.error(MESSAGE_HTML_ERROR_ACCESS(), {
						duration: 5000,
						dismissible: true,
						id: "error-permission",
					});
				});

				return of(true);
			})
		);
	}
}
