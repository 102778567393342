import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from "@angular/router";

import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../services";

@Injectable({ providedIn: "root" })
export class MustBeUserProfile implements CanActivate {
	constructor(private router: Router, private _authService: AuthService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> {
		return this._authService.getUserRoles().pipe(
			catchError(() => {
				return of(true);
			}),
			map((result) => Boolean(result))
		);
	}
}
