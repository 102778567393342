<div class="layout-bot-telegram">

  <!-- Header -->
  <app-header-bot-telegram></app-header-bot-telegram>
  
  <!-- Content -->
  <main class="layout-portal__main">
    <router-outlet></router-outlet>
  </main>

  
  
</div>