import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { JSONResponse } from "../models/json-response";
import { UserPermission } from "../models/user-permission";
import { CrudService } from "./contracts/crud-service";

// Todo: JsonResponse Success
// Todo: JsonResponse Error

@Injectable({
  providedIn: "root",
})
export class UserPermissionService implements CrudService<UserPermission> {
  private urlBackEndDashboardIptApi = `${environment.urlBackendPortal}/${environment.slugBackend}`;

  constructor(private http: HttpClient) {}

  findById(id: number): Observable<UserPermission> {
    throw new Error("Method not implemented!");
  }

  findPermissionByUserLogged() {
    return this.http.get<any>(
      `${this.urlBackEndDashboardIptApi}/listar-permisos-usuarios`
    );
  }

  /**
   * Obtiene todos los permisos, entre ellos tambien los del usuario con una propiedad 'is_asignated'
   * @param userId : id usuario
   * @returns
   */
  findPermissionByUserId(userId: number): Observable<any> {
    return this.http.get<any>(
      `${this.urlBackEndDashboardIptApi}/admin/listar-permiso-por-id-usuario/${userId}`
    );
    // .pipe(map(this.toJSONResponse));
  }

  updatePermissionsUser(
    userId: number,
    permissionIds: number[]
  ): Observable<any> {
    return this.http.post(
      `${this.urlBackEndDashboardIptApi}/admin/eliminar-permisos-usuarios/`,
      {
        usuario_id: userId,
        permisos_ids: permissionIds,
      }
    );
  }

  private toJSONResponse(httpResponse?: any): JSONResponse<UserPermission> {
    return {
      data: httpResponse.data.map(
        (be) =>
          new UserPermission({
            id: be.permiso_id,
            permission_name: be.permiso_nombre,
            checked: be.is_asignated,
            application_name: be.aplicacion_nombre,
          })
      ),
    };
  }
}
