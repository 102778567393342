<div class="container">
    <!-- Title -->
    <mat-card class="title-card">
        <span class="title">{{ titulo }}</span>
    </mat-card>
    <!-- Content -->
    <mat-card class="form-card" >
        <!-- Form -->
        <form [formGroup]="form" (ngSubmit)="agregarUsuarioButton()">
            <mat-grid-list cols="5" rowHeight="70px">
                <mat-grid-tile [colspan]="1" [rowspan]="6">
                    <!-- <img src="./assets/img/adduser.png" style="width: 100%; max-width: 200px;" > -->
                </mat-grid-tile>
                <!-- ID Usuario -->
                <mat-grid-tile [colspan]="3" [rowspan]="1">
                    <mat-form-field class="ancho" appearance="fill">
                        <mat-label>ID de usuario</mat-label>
                        <input  matInput formControlName="idUser">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="6">
                </mat-grid-tile>
                <!-- FirstName -->
                <mat-grid-tile [colspan]="3" [rowspan]="1">
                    <mat-form-field class="ancho" appearance="fill">
                        <mat-label>Primer nombre</mat-label>
                        <input matInput formControlName="firstName">
                    </mat-form-field>
                </mat-grid-tile>
                <!-- LastName -->
                <mat-grid-tile [colspan]="3" [rowspan]="1">
                    <mat-form-field class="ancho" appearance="fill">
                        <mat-label>Primer apellido</mat-label>
                        <input matInput formControlName="lastName">
                    </mat-form-field>
                </mat-grid-tile>
                <!-- Rol -->
                <mat-grid-tile [colspan]="3" [rowspan]="1">
                    <mat-form-field class="ancho" appearance="fill">
                        <mat-label>Rol</mat-label>
                        <mat-select formControlName="rol" [(value)]="selected">
                            <mat-option *ngFor="let rol of roles" [value]="rol.value">
                                {{rol.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-grid-tile>
                <!-- Email -->
                <mat-grid-tile [colspan]="3" [rowspan]="1">
                    <mat-form-field class="ancho" appearance="fill">
                        <mat-label>Correo</mat-label>
                        <input matInput formControlName="correo">
                    </mat-form-field>
                </mat-grid-tile>
                <!-- Actions -->
                <mat-grid-tile [colspan]="3" [rowspan]="1">
                    <button type="button" mat-raised-button (click)="cancelar(true)" style="margin-right: 20px;">Cancelar</button>
                    <button type="submit" [disabled]="form.invalid" mat-raised-button color="primary">Aceptar</button>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </mat-card>
</div>
