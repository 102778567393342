import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { UserProfileEvents } from "@app/capacitacion-tecnicos/core/models/user";
import { IPermissions, TypeUserPermissions } from "../interfaces/permissions";
import { environment } from "src/environments/environment";
import { tap } from "rxjs/operators";

const httpOptions = {
	headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
	providedIn: "root",
})
export class AuthService {
	private _basePathEventos = environment.urlEventosCampos;
	private _userPermissions = new BehaviorSubject<TypeUserPermissions>([]);
	private _permissions = new BehaviorSubject<IPermissions[]>([]);
	private _userProfileEvents = new BehaviorSubject<UserProfileEvents | null>(
		null
	);

	userPermissions = this._userPermissions.asObservable();
	permissions = this._permissions.asObservable();
	public userLoggedIn$ = this._userProfileEvents.asObservable();

	constructor(private http: HttpClient) {}

	// with value
	getUserPermissions() {
		return this._userPermissions.value;
	}

	// with getValue
	getValueUserPermissions() {
		return this._userPermissions.getValue();
	}

	getUserRoles(): Observable<any> {
		return this.http
			.get(`${this._basePathEventos}/users/profile/`)
			.pipe(tap((data) => this._handleSuccessProfile(data)));
	}

	setUserPermissions(permissions: TypeUserPermissions) {
		this._userPermissions.next(permissions);
	}

	checkUserPermissions(possiblePermissions: TypeUserPermissions): boolean {
		// si el array es empty entonces tiene acceso al recurso
		if (!possiblePermissions.length) return true;

		return possiblePermissions.some((permission) =>
			this._userPermissions.value.includes(permission)
		);
	}

	updateUserProfile(data: any) {
		this._handleSuccessProfile(data);
	}

	get currentUserProfileEvents(): UserProfileEvents {
		return (
			this._userProfileEvents.value ||
			JSON.parse(localStorage.getItem("user-data"))
		);
	}

	private _handleSuccessProfile(data: any) {
		this._userProfileEvents.next(data);
		localStorage.setItem("user-data", JSON.stringify(data));
	}

	/**
	 * Guarda los permisos filtrados como objeto IPermissions
	 * @param permissions {IPermissions}
	 */
	setUserPermissionsAsObject(permissions: IPermissions[]) {
		this._permissions.next(permissions);
	}

	// with value
	getPermissions() {
		return this._permissions.value;
	}

	// with getValue
	getValuePermissions() {
		return this._permissions.getValue();
	}
}
