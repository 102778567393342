import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthBotTelegramGuard } from './core/guards/authBotTelegram.guard';
import { UsuarioComponent } from './usuario/usuario.component';
import { LayoutBotTelegramComponent } from './layout/layout-bot-telegram/layout-bot-telegram.component';

import { IS_ADMIN_DASHBOARD_IPT } from '../dashboard-ipt/data/permissions';
import { MustHavePermissions } from '../core/guards';
import { HomeBotTelegramComponent } from './components/home-bot-telegram/home-bot-telegram.component';


const routes: Routes = [
  {
    path: "bot-telegram",
    component: LayoutBotTelegramComponent,
    canActivate: [AuthBotTelegramGuard],
    children: [
      {
        path: "",
        component: HomeBotTelegramComponent,
        canActivate: [MustHavePermissions],
        data: {
          permissions: [],
        },
      },
      {
        path: "usuarios",
        component: UsuarioComponent,
        canActivate: [MustHavePermissions],
        data: {
          permissions: [],
        },
      },
      {
				path: "**",
				// component: Custom404
				redirectTo: "/bot-telegram",
			},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BotTelegramRoutingModule { }
