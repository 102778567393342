import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

import { Observable, of } from "rxjs";
import { delay, switchMap } from "rxjs/operators";
import { UserPermissionService } from "src/app/portal/core/services/user-permission.service";
import { AuthService } from "../../../core/services/auth.service";

@Injectable({ providedIn: "root" })
export class AuthBotTelegramGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userPermissionService: UserPermissionService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.userPermissionService.findPermissionByUserLogged().pipe(
      switchMap((response) => {
        this.authService.setUserPermissions(
          response.data.map((i) => i.per_name.toUpperCase())
        );
        return of(true);
      })
    );
  }
}
