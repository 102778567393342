import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
  selector: 'app-header-bot-telegram',
  templateUrl: './header-bot-telegram.component.html',
  styleUrls: ['./header-bot-telegram.component.scss']
})
export class HeaderBotTelegramComponent implements OnInit {

  fullname: string;
  useremail: string;
  routeBoolean : Boolean;

  constructor(
    private msalService: MsalService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setupUser();
    this.setupMenuNavigation();
  }

  private setupUser(): void {
    const user = this.msalService.instance.getActiveAccount();
    this.fullname = user?.name.toUpperCase();
    this.useremail = user?.username;

  }

  route = {
    to: "usuarios",
    text: "Usuarios",
    key: "group",
    icon: "group",
    permissions: [],
  }

  private setupMenuNavigation(): void {
    this.authService.userPermissions.subscribe({
      next: () => {
        const _route = this.authService.checkUserPermissions(this.route.permissions)
        this.routeBoolean = _route;
      },
    });
  }

  onLogout() {
    this.msalService.logoutRedirect();
  }

  trackBy(index: number, el: any): number {
    return el.key;
  }


}
