
export const SLUG_PORTAL_INICIO              = "aplicaciones"; // aplicaciones
export const SLUG_PORTAL_USUARIOS            = "usuarios";
export const SLUG_PORTAL_USER_PERMISOS       = "permisos-usuario";
export const SLUG_PORTAL_APP_PERMISOS        = "permisos-app";
export const SLUG_PORTAL_PERFIL              = "perfil";
export const SLUG_PORTAL_NEWS                = "comunicados";
export const SLUG_PORTAL_NEWS_HISTORY        = "historial-comunicados";
export const SLUG_PORTAL_MANAGE_APLICACIONES = "mantenimiento-aplicaciones";
export const SLUG_PORTAL_PERIODOS            = "periodos";


export const PATH_PORTAL_NEWS_HISTORY = `../${SLUG_PORTAL_NEWS_HISTORY}`;
export const PATH_PORTAL_PERIODOS     = `../${SLUG_PORTAL_PERIODOS}`;



export const SLUG_PORTAL_ERROR              = "user-error";
