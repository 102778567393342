<div class="panel-container">
    <mat-card class="card-home-page">
        <mat-card class="title-card">
            <h1 class="title">Gestor de usuarios del Lean Noc BOT en Telegram</h1>
        </mat-card>
        <mat-card class="image-card">
            <img mat-card-image src="../../../../assets/images/bot-telegram.png">
        </mat-card>
    </mat-card>
</div>

